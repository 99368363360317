import * as React from 'react';
import { graphql } from 'gatsby';
import { DefaultLayout } from '../layouts/DefaultLayout';
import CMSPage from '../components/Content/CMSPage';
import { Metadata } from '@mangoart/gatsby-ui/components/Metadata';

function DatenschutzPage({ data, location }) {
  const { page, site } = data;
  const { siteMetadata } = site;
  const { metadata, opengraph } = page;

  return (
    <DefaultLayout>
      <Metadata
        data={metadata}
        opengraphData={opengraph}
        defaultTitle={siteMetadata.title}
        canonicalSiteUrl={siteMetadata.canonicalSiteUrl}
        path={location.pathname}
      />
      <div className="wrap">
        <CMSPage content={page} id="datenschutz" />
      </div>
    </DefaultLayout>
  );
}

export default DatenschutzPage;

export const DatenschutzPageQuery = graphql`
  query DatenschutzContent($language: String) {
    site: site {
      siteMetadata {
        title
        canonicalSiteUrl
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: cockpitRegionDatenschutz(lang: { eq: $language }) {
      title
      teaser
      identifier
      cssclass
      content
      images {
        publicURL
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG)
        }
      }
      metadata {
        title
        metakeywords
        metadescription
      }
      opengraph {
        title
        description
        type
        image {
          publicURL
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`;
